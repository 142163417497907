import clsx from 'clsx';
import { Link } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DonutLove from '../../assets/svg/ufo.svg';

const Error404 = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        'flex flex-col justify-center items-center px-4 w-full text-center',
        className
      )}
    >
      <DonutLove
        alt={t('Not Found Image')}
        className="w-full md:w-1/3 h-auto"
      />
      <h1 className="my-8">{t("404: Oops! You're lost.")}</h1>
      <Link to="/" className="btn btn-sm btn-solid btn-oxford-blue">
        {t('Go Home')}
      </Link>
    </div>
  );
};

export default Error404;
