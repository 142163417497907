import React from 'react';
import Error404 from '../components/Error/404';
import Layout from '../layouts/Layout';

const Page404 = () => {
  return (
    <Layout>
      <Error404 className="my-12" />
    </Layout>
  );
};

export default Page404;
